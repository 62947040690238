import { HIDE_MODAL, SHOW_MODAL } from "../types";
import _noop from "lodash/noop";
import modalComponents from "../../components/Modal/ModalComponents";

const initialState = {
  visible: false,
  title: null,
  content: null,
  handleOk: _noop,
};

function modalReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case SHOW_MODAL:
      const {
        title,
        modalType,
        handleOk,
        values = {},
        functions = {},
      } = payload;
      return {
        ...state,
        visible: true,
        title,
        content: modalComponents[modalType],
        handleOk,
        values,
        functions,
      };
    case HIDE_MODAL:
      return { ...state, visible: false };
    default:
      return state;
  }
}

export default modalReducer;
