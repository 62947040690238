import { message } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import LpButton from "../../../ui/Button/Button";
import LpInput from "../../../ui/Input/Input";
import OtpInput from "react-otp-input";
import { hideModal, showModal } from "../../../redux/actions/modal";
import { useDispatch } from "react-redux";
import { TRANSACTION_PIN } from ".";
import Tag from "../../../ui/Tag/Tag";
import { maskNumber } from "../../../helpers/numbers";

const AccessCheck = ({ phone, beginTransaction }) => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState(maskNumber(phone));
  const [otpVisible, setOTPVisible] = useState(false);
  const [otp, setOTP] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const handleChange = useCallback((e) => setNumber(e.target.value), []);
  const validate = (value) => {
    const isMatch = /^([0|+[0-9]{1,4})?([7-9][0-1][0-9]{8})$/.test(value);
    return isMatch;
  };

  // const handleSubmit__ = useCallback(() => {
  //   const { phoneNo, isLogin = false } = location.state;
  //   const payload = { phoneNo, token: otp };
  //   setLoading(true);
  //   dispatch(verifyCode(payload, navigate, isLogin));
  // }, [dispatch, location, navigate, otp]);

  const handleSubmit = useCallback(() => {
    dispatch(hideModal());
    dispatch(
      showModal({
        modalType: TRANSACTION_PIN,
        handleOk: () => console.log("testing OK!"),
        functions: { onComplete: () => beginTransaction() },
      })
    );
  }, [dispatch, beginTransaction]);

  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);

  const sendOTP = useCallback(() => {
    /**OTP logic goes here */
    if (!validate(number)) {
      message.error("Please check that the number is correct!");
      return;
    }
    setOTPVisible(true);
  }, [number]);

  const onClickTag = useCallback(() => {
    if (phone) {
      setCanEdit(true);
      setNumber(phone);
    }
  }, [phone]);

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Authorize access</h3>
        {!otpVisible ? (
          <p>Please confirm your mobile number below</p>
        ) : (
          <p>Please input the 6-digit code sent to you via SMS</p>
        )}
      </div>

      {!otpVisible ? (
        <Fragment>
          <LpInput
            value={number}
            inputClass="access-check__input masked__input"
            onChange={handleChange}
            wrapperClass="access-check"
            autoFocus
            disabled={!canEdit}
            contentLeft={
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    d="M14.4183 5.98988C13.9422 5.90194 13.505 6.20573 13.4144 6.67041C13.3238 7.1351 13.6285 7.58878 14.0916 7.67972C15.4859 7.95153 16.5624 9.03079 16.8353 10.4298V10.4308C16.913 10.8336 17.2675 11.1264 17.6759 11.1264C17.7306 11.1264 17.7854 11.1214 17.8412 11.1114C18.3043 11.0184 18.609 10.5657 18.5184 10.1001C18.1111 8.01049 16.5027 6.3966 14.4183 5.98988Z"
                    fill="#2C264B"
                  />
                  <path
                    d="M14.3561 2.50793C14.133 2.47595 13.9089 2.54191 13.7307 2.68381C13.5474 2.82771 13.4329 3.03557 13.408 3.26841C13.3552 3.73908 13.6948 4.16479 14.1649 4.21776C17.4065 4.57951 19.9262 7.10477 20.2907 10.3565C20.3395 10.7922 20.705 11.121 21.1412 11.121C21.174 11.121 21.2059 11.119 21.2388 11.115C21.4668 11.09 21.67 10.9771 21.8134 10.7972C21.9558 10.6174 22.0206 10.3935 21.9947 10.1647C21.5405 6.10746 18.4005 2.95862 14.3561 2.50793Z"
                    fill="#2C264B"
                  />
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0317 13.4724C15.0208 17.4604 15.9258 12.8467 18.4656 15.3848C20.9143 17.8328 22.3216 18.3232 19.2192 21.4247C18.8306 21.737 16.3616 25.4943 7.6846 16.8197C-0.993478 8.144 2.76158 5.67244 3.07397 5.28395C6.18387 2.17385 6.66586 3.58938 9.11449 6.03733C11.6544 8.5765 7.04266 9.48441 11.0317 13.4724Z"
                  fill="#2C264B"
                />
              </svg>
            }
            contentRight={<Tag label="Edit" onClick={onClickTag} />}
          />

          <div className="modal_components__button">
            <LpButton label="Send One Time Password" onClick={sendOTP} />
          </div>
        </Fragment>
      ) : (
        <div style={{ marginTop: 30 }}>
          <div className="justify-content-center">
            <OtpInput
              value={otp}
              onChange={setOTP}
              numInputs={6}
              className="otp-input"
              separator={<div style={{ marginRight: 20 }} />}
              shouldAutoFocus
              isInputNum
              handleKeyUp={handleKeyUp}
            />
          </div>
          <div className="modal_components__button">
            <LpButton label="Continue" onClick={handleSubmit} />
          </div>
        </div>
      )}

      <br />
      <p className="text-align-center">
        Dont have an Account? <a href="/signup">Sign Up</a>
      </p>
    </div>
  );
};

export default AccessCheck;
