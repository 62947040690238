import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";
//import { setTransactionPin } from "../../redux/actions/user";
import { message } from "antd";
import TransactionPin from "../../ui/TransactionPin/TransactionPin";
import Layout from "./Layout";

function SetLoginPin() {
  const dispatch = useDispatch();

  // const [loading, setLoading] = useState(false);
  const [activeBreadcrumb, setActiveBreadcrumb] = useState(4);
  const [pin, setPin] = useState("");

  const handleSubmit = useCallback(() => {
    // setLoading(true);
    const payload = { pin };
    /* dispatch(setTransactionPin(payload)); */
  }, [dispatch, pin]);

  const onPinSuccess = useCallback((pin) => setPin(pin), []);

  const onComplete = useCallback(
    (newPin) => {
      if (pin !== newPin) {
        message.error("Previous PIN does not match Confirm PIN");
        return;
      }
      handleSubmit();
      //   window.location.href = "/";
    },
    [pin, handleSubmit]
  );

  return (
    <div className="auth">
      <Layout
        title={
          pin.length < 4 ? "Set Transaction PIN" : "Confirm Transaction PIN"
        }
        subTitle="This would be used to authorise your transactions"
        activeKey={activeBreadcrumb}
        onClick={setActiveBreadcrumb}
      >
        {pin.length < 4 && (
          <div className="form">
            <div>
              <TransactionPin onComplete={onPinSuccess} />
            </div>
          </div>
        )}

        {pin.length === 4 && (
          <div className="form">
            <div id="slide">
              <TransactionPin onComplete={onComplete} />
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default SetLoginPin;
