import { call, all, takeEvery } from 'redux-saga/effects';
import { sendOtpRequest, verifyOtpRequest } from '../../api/otpVerification'; // API imports
import { sendOtp, verifyOtp } from '../actions/otpVerification'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { setAccessToken, translateErrorResponse } from '../../utils/utils';
import { message } from 'antd';

// Saga to handle sending OTP
export function* handleSendOtp(action) {
  try {
    const { data } = yield call(sendOtpRequest, action.payload);
    yield call(resolvePromiseAction, action, data); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    message.error(errorMessage);
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle verifying OTP
export function* handleVerifyOtp(action) {
  try {
    const { data: response } = yield call(verifyOtpRequest, action.payload);
  
    const actionType = action.payload?.action?.toLowerCase();

    if (actionType === "login_with_username_and_otp") {
      const responseData = response?.data?.data ?? response?.data;
      yield setAccessToken(responseData?.access_token);
    }

    const responseData = {actionType, ...(response?.data ?? response)};

    yield call(resolvePromiseAction, action, responseData); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for OTP actions
export default function* otpVerificationSaga() {
  yield all([
    takeEvery(sendOtp.trigger, handleSendOtp), // Watch for trigger action
    takeEvery(verifyOtp.trigger, handleVerifyOtp), // Watch for trigger action
  ]);
}
