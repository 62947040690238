const internetIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8344 10.5701H10.3944V9.60214C10.3944 9.27414 10.1224 9.00214 9.79439 9.00214C9.45839 9.00214 9.19439 9.27414 9.19439 9.60214V10.5701H4.44236C3.11436 10.5701 2.03436 11.6501 2.03436 12.9781V14.2581C2.03436 15.5861 3.11436 16.6661 4.44236 16.6661H14.8344C16.1544 16.6661 17.2344 15.5861 17.2344 14.2581V12.9781C17.2344 11.6501 16.1544 10.5701 14.8344 10.5701Z"
      fill={fill}
    />
    <path
      d="M7.48239 14.2172H7.89039C8.21841 14.2172 8.49041 13.9532 8.49041 13.6172C8.49041 13.2892 8.21841 13.0172 7.89039 13.0172H7.48239C7.14639 13.0172 6.88239 13.2892 6.88239 13.6172C6.88239 13.9532 7.14639 14.2172 7.48239 14.2172Z"
      fill={fill}
    />
    <path
      d="M5.03439 14.2172H5.44239C5.77039 14.2172 6.04239 13.9532 6.04239 13.6172C6.04239 13.2892 5.77039 13.0172 5.44239 13.0172H5.03439C4.70639 13.0172 4.43439 13.2892 4.43439 13.6172C4.43439 13.9532 4.70639 14.2172 5.03439 14.2172Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.875 6.15835C6.9222 5.11115 8.31257 4.53435 9.79177 4.53435C11.2726 4.53435 12.663 5.11115 13.7102 6.15835C13.827 6.27515 13.9806 6.33435 14.1342 6.33435C14.2878 6.33435 14.4414 6.27515 14.5582 6.15835C14.7926 5.92475 14.7926 5.54475 14.5582 5.30955C13.2862 4.03595 11.5926 3.33435 9.79177 3.33435C7.9918 3.33435 6.299 4.03595 5.0262 5.30955C4.7918 5.54475 4.7918 5.92475 5.0262 6.15835C5.2614 6.39275 5.6414 6.39195 5.875 6.15835Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79144 6.27789C8.77784 6.27789 7.8258 6.67309 7.109 7.38989C6.8746 7.62429 6.8746 8.00429 7.109 8.23869C7.2266 8.35549 7.3802 8.41393 7.5338 8.41393C7.6874 8.41393 7.841 8.35549 7.9578 8.23789C8.44744 7.74749 9.09864 7.47789 9.79144 7.47789C10.4834 7.47789 11.1346 7.74749 11.6242 8.23789C11.8578 8.47233 12.2378 8.47233 12.473 8.23869C12.7074 8.00429 12.7074 7.62429 12.473 7.38989C11.757 6.67309 10.8042 6.27789 9.79144 6.27789Z"
      fill={fill}
    />
  </svg>
);

export default internetIcon;
