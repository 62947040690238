import React, { useCallback, useState } from "react";
import LpInput from "../../../../ui/Input/Input";
import LpButton from "../../../../ui/Button/Button";
import { message } from "antd";
import { verifyAddress } from "../../../../api/user";
import SuccessModal from "../SuccessModal";
import { current } from "@reduxjs/toolkit";

const AddressVerification = ({ currentDisplay }) => {
  const [formData, setFormData] = useState({
    apartment: "",
    streetAddress: "",
    country: "",
    state: "",
  });

  const { apartment, streetAddress, country, state } = formData;

  const [display, setDisplay] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const errorMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.error({
        key,
        type: "error",
        content: "Something went wrong",
        duration: 2,
      });
    }, 1000);
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const inputFields = [
      { id: "streetAddress", value: streetAddress },
      { id: "apartment", value: apartment },
      { id: "country", value: country },
      { id: "state", value: state },
    ];

    const firstEmptyInputField = inputFields.find(
      (inputField) => inputField.value.trim() === ""
    );

    if (firstEmptyInputField) {
      document.getElementById(firstEmptyInputField.id).focus();
    } else {
      try {
        const addressInfo = {
          street: streetAddress,
          apartmentNo: apartment,
          country: country,
          state: state,
        };

        const response = await verifyAddress(addressInfo);
        if (response.status === 200) {
          currentDisplay("submitted");
          setDisplay(1);
        }
      } catch (error) {
        console.error(error);
        errorMessage();
      }
    }
  }, [apartment, streetAddress, country, state]);

  return (
    <div className="modal_components">
      {contextHolder}
      {display === 0 && (
        <>
          <div className="centered_text">
            <h3>Address Verification</h3>
            <p>
              Please fill in the important details below to complete the level 3
              verification
            </p>
          </div>

          <LpInput
            inputName="streetAddress"
            value={streetAddress}
            inputMode="text"
            onChange={handleInputChange}
            label="Street Address"
            placeholder="Bamsufic Estate"
          />

          <LpInput
            inputName="apartment"
            value={apartment}
            inputMode="text"
            onChange={handleInputChange}
            autoFocus
            label="Apartment/Suit no."
            placeholder="Eg. 44"
          />

          <LpInput
            inputName="country"
            value={country}
            inputMode="text"
            onChange={handleInputChange}
            label="Country"
            placeholder="Eg. Nigeria"
          />

          <LpInput
            inputName="state"
            value={state}
            inputMode="text"
            onChange={handleInputChange}
            label="State"
            placeholder="Eg. Enugu"
          />

          <div className="modal_components__button">
            <LpButton
              label="Submit"
              onClick={handleSubmit}
              customClass="full-width"
            />
          </div>
        </>
      )}
      {display === 1 && (
        <SuccessModal
          title="Address submitted"
          subtitle="Your submission has been received and approved"
        />
      )}
    </div>
  );
};

export default AddressVerification;
