import { useLocation, useNavigate } from "react-router-dom";
import withUserData from '../../HOC/withUserData';
import { useEffect } from "react";
import { NAV_ELECTRICITY } from "../../routes/routes.constant";
import { getNavPath } from "../../routes/routes.helpers";


const PaymentConfirmation = ({ userData }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
  

    useEffect(() => {
        if (queryParams.get('tx_ref')) {
            const state = {state: { transactionReference: queryParams.get('tx_ref'), paymentStatus: queryParams.get('status') } };
            navigate(`${getNavPath(NAV_ELECTRICITY)}/done`, state);
        }
    }, [queryParams]);

    return (
        <></>
    );
}

export default withUserData(PaymentConfirmation);
