import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SpOptionButtonGroup from './../SpOptionButtonGroup/SpOptionButtonGroup';
import { paymentOptions, paymentOptionsForGuest } from '../../constants';
import withUserData from '../../HOC/withUserData';

const SpPaymentOptions = ({ loggedInAsGuest = true, title, items, onSelected, defaultOptionIndexes }) => {
    const [options, setOptions] = useState(items);
    const [selectedIndexes, setSelectedIndexes] = useState(defaultOptionIndexes);

    useEffect(() => {
        if (loggedInAsGuest) {
            setOptions(paymentOptionsForGuest);
        } else {
            setOptions(paymentOptions);
        }
    }, [loggedInAsGuest]);

    const handleSelection = (item, selectedIndex) => {
        setSelectedIndexes([selectedIndex]);

        if (typeof onSelected === 'function') {
            onSelected(selectedIndex, item);
        }
    };

    return (
        <div>
            <p>{title}</p>
            <div>
                <SpOptionButtonGroup
                    buttons={options}
                    direction="column"
                    onSelected={handleSelection}
                    //defaultActiveIndexes={selectedIndexes}
                    className='opacity-filter'
                />
            </div>
        </div>
    );
};

SpPaymentOptions.propTypes = {
    title: PropTypes.node,
    onSelected: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.node,
            disabled: PropTypes.bool,
        })
    ),
    defaultOptionIndexes: PropTypes.arrayOf(PropTypes.number),
};

SpPaymentOptions.defaultProps = {
    title: "Payment Options",
    items: [],
    defaultOptionIndexes: [],
    onSelected: () => {},
};

export default withUserData(SpPaymentOptions);
