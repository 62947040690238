import React from 'react';
import PropTypes from 'prop-types';

const SpStatus = ({ type, children }) => {
  return (
    <span className={`sp-status ${type}`}>
      {children}
    </span>
  );
};

SpStatus.propTypes = {
  type: PropTypes.oneOf(['primary', 'success', 'danger', 'info', 'warning']).isRequired,
  children: PropTypes.node.isRequired,
};

export default SpStatus;
