import { createReducer } from '@reduxjs/toolkit';
import {
  validateAccount,
  validateAccountReset,
} from '../actions/accountValidation'; // Update import path as needed
import { VALIDATE_ACCOUNT_RESET } from '../types';

const initialState = {
  data: null,
  isValid: false,
  isLoading: false,
  error: null,
};

const accountValidationReducer = createReducer(initialState, {
  [validateAccount.trigger]: (state) => {
    state.isLoading = true;
    state.error = null;
    state.data = null;
  },
  [validateAccount.resolved]: (state, action) => {
    state.isLoading = false;
    state.isValid = true;
    state.error = null;
    state.data = action.payload;
  },
  [validateAccount.rejected]: (state, action) => {
    state.isLoading = false;
    state.isValid = false;
    state.error = action.payload;
  },
  [VALIDATE_ACCOUNT_RESET]: () => initialState,
});

export default accountValidationReducer;
