import { call, all, takeEvery } from 'redux-saga/effects';
import { fetchServiceCategoriesRequest, fetchServicesByCategoryRequest } from '../../api/services'; // API imports
import { fetchServiceCategories, fetchServicesByCategory } from '../actions/service'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils';

// Saga to handle fetching service categories
export function* handleFetchServiceCategories(action) {
  try {
    const { data: response } = yield call(fetchServiceCategoriesRequest);
    yield call(resolvePromiseAction, action, response?.data ?? []); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle fetching services by category
export function* handleFetchServicesByCategory(action) {
  try {
    const { data: response } = yield call(fetchServicesByCategoryRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? []); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for service actions
export default function* serviceSaga() {
  yield all([
    takeEvery(fetchServiceCategories.trigger, handleFetchServiceCategories), // Watch for fetch service categories action
    takeEvery(fetchServicesByCategory.trigger, handleFetchServicesByCategory), // Watch for fetch services by category action
  ]);
}
