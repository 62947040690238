import { combineReducers } from "redux";
import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import transactionReducer from "./transactionReducer";
import serviceReducer from "./serviceReducer";
import accountValidationReducer from "./accountValidationReducer";
import otpVerificationReducer from "./otpVerificationReducer";
import accountReducer from "./account";

const reducers = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  user: userReducer,
  transaction: transactionReducer,
  service: serviceReducer,
  accountValidation: accountValidationReducer,
  otpVerification: otpVerificationReducer,
  account: accountReducer
});

export default reducers;
