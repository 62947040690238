const electricityIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M3.90649 8.17291C3.90649 4.38794 7.36219 1.41981 11.2741 2.21226C13.5976 2.68743 15.474 4.55275 15.9597 6.87719C16.5032 9.49618 15.3416 11.943 13.3727 13.2498C13.2454 13.3343 13.0947 13.3752 12.9417 13.3752H11.042H8.95865H7.07776C6.9265 13.3752 6.77733 13.3352 6.65076 13.2523C4.99789 12.1708 3.90649 10.3009 3.90649 8.17291Z"
      fill={fill}
    />
    <path
      d="M9.37535 8.56442V12.9593C9.37535 13.1894 9.18876 13.3759 8.95868 13.3759H11.042C10.8118 13.3759 10.6253 13.1894 10.6253 12.9593V8.56442C10.6253 8.21928 10.3455 7.93945 10.0003 7.93945C9.65518 7.93945 9.37535 8.21928 9.37535 8.56442Z"
      fill={fill}
    />
    <path
      d="M12.6548 15.0423C12.6548 14.8122 12.4682 14.6257 12.2381 14.6257H7.77146C7.54135 14.6257 7.3548 14.8122 7.3548 15.0423V15.2669C7.3548 16.7254 8.54676 17.9173 10.0052 17.9173C11.4629 17.9173 12.6548 16.7253 12.6548 15.2669V15.0423Z"
      fill={fill}
    />
  </svg>
);

export default electricityIcon;
