import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Layout from "./Layout";
import TransactionPin from "../../ui/TransactionPin/TransactionPin";
import { useLocation, useNavigate } from "react-router-dom";
import { register } from "../../redux/actions/auth";
import SpLoadingOverlay from "../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import { sleep } from './../../utils/utils';

function AuthTransactionPin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const formData = location.state || {};
  const [pin, setPin] = useState("");

  const registerState = useSelector(state => state.auth.register);
  const { loading: registerLoading } = registerState;

  const handleSubmit = useCallback(() => {
    const payload = { ...formData, pin };

    dispatch(register(payload))
      .then((response) => {
        message.success(response?.message);
        
        sleep(500).then(() => {
          navigate('/', {replace: true});
        });
      })
      .catch(error => {
        message.error(error);
        setPin("");
      });

  }, [dispatch, formData, pin]);

  const onPinSuccess = useCallback((pin) => setPin(pin), []);

  const onComplete = useCallback(
    (newPin) => {
      if (pin !== newPin) {
        message.error("Previous PIN does not match Confirm PIN");
        return;
      }

      handleSubmit();
    },
    [pin]
  );

  return (
    <SpLoadingOverlay isLoading={registerLoading} style={{padding: 0}}>
      <div className="auth">
        <Layout
          title={
            pin.length < 4 ? "Set Transaction PIN" : "Confirm Transaction PIN"
          }
          subTitle="This would be used to authorise your transactions"

        >
          {pin.length < 4 && (
            <div className="form">
              <div>
                <TransactionPin onComplete={onPinSuccess} />
              </div>
            </div>
          )}

          {pin.length === 4 && (
            <div className="form">
              <div id="slide">
                <TransactionPin onComplete={onComplete} />
              </div>
            </div>
          )}
        </Layout>
      </div>
    </SpLoadingOverlay>
  );
}

export default AuthTransactionPin;
