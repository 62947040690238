import React, { useCallback, useRef } from "react";
import LpButton from "../../ui/Button/Button";
import Tag from "../../ui/Tag/Tag";

import _upperFirst from "lodash/upperFirst";
import { getAmount } from "../../pages/Services/Services.helpers";
import PrintScreen from "./PrintScreen";
import { useReactToPrint } from "react-to-print";
import { message } from "antd";
import { formatDate } from "../../helpers/date";

function BillReceipt({
  account,
  name,
  address,
  token,
  date,
  reference,
  status,
  logo,
  serviceType,
  serviceAmount,
  transactionType,
  disco,
  onClick,
  transactionPage,
  powerUnit,
  handleMakeRecurringPayment,
  recurringPaymentRequestLoading,
}) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // copyStyles: true,
    // fonts: [
    //   {
    //     family: "General Sans",
    //     style: "normal",
    //     weight: 200,
    //     source: "url(https://fonts.cdnfonts.com/css/general-sans)",
    //   },
    //   {
    //     family: "General Sans Bold",
    //     style: "italic",
    //     weight: 800,
    //     source: "url(../../assets/fonts/GeneralSans-Bold.ttf)",
    //   },
    // ],
    documentTitle: `${reference}`,
  });

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(token);
    message.success("Copied to clipboard!");
  }, [token]);

  return (
    <div>
      <div className="bill-receipt">
        <img className="logo" src={logo} alt="Service Logo" />
        <Tag label={_upperFirst(serviceType)} />
        <br />
        <div className="sub-field-value">Total Amount</div>
        <div className="amount-field">{getAmount(serviceAmount)}</div>
        <br />
        <br />
        <div className="bill-receipt__row">
          <div className="field-label">Beneficiary</div>
          <div className="multi-value">
            <div className="field-value">{account}</div>
            <div className="sub-field-value">{name}</div>
            <div className="sub-field-value">{address}</div>
          </div>
        </div>
        <div className="bill-receipt__row">
          <div className="field-label">Token</div>
          <div className="field-value">
            <div className="row align-items-center">
              <div
                className="mr-xs justify-space-between cursor-pointer"
                onClick={handleCopy}
              >
                <ion-icon name="copy-outline"></ion-icon>
              </div>
              {token}
            </div>
          </div>
        </div>
        <div className="bill-receipt__row">
          <div className="field-label">Power Unit</div>
          <div className="field-value">{powerUnit}</div>
        </div>
        <div className="bill-receipt__row">
          <div className="field-label">Date & Time</div>
          <div className="field-value">{formatDate(date)}</div>
        </div>
        <div className="bill-receipt__row">
          <div className="field-label">Reference</div>
          <div className="field-value">{reference}</div>
        </div>
        <div className="bill-receipt__row">
          <div className="field-label">Status</div>
          <div className="field-value success">{status}</div>
        </div>
      </div>
      <div className="bill-receipt__buttons">
        {!transactionPage && (
          <>
            <LpButton label="Download" onClick={handlePrint} />
            <LpButton
              label="Make recurring payment"
              buttonType="bordered"
              onClick={handleMakeRecurringPayment}
              loading={recurringPaymentRequestLoading}
            />{" "}
          </>
        )}
        {transactionPage && (
          <>
            <LpButton label="Download" onClick={handlePrint} />
            <LpButton
              label="Back to transactions"
              buttonType="bordered"
              onClick={onClick}
            />
          </>
        )}
      </div>

      <PrintScreen
        ref={printRef}
        account={account}
        name={name}
        address={address}
        token={token}
        date={date}
        reference={reference}
        status={status}
        logo={logo}
        serviceType={serviceType}
        serviceAmount={serviceAmount}
        transactionType={transactionType}
        disco={disco}
      />
    </div>
  );
}

BillReceipt.defaultProps = {
  transactionPage: false,
};

export default BillReceipt;
