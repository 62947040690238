import { Footer } from "antd/es/layout/layout";
import React from "react";
import { Link } from "react-router-dom";
import { getNavPath } from "../../routes/routes.helpers";
import {
  NAV_ABOUT_US,
  NAV_PRIVACY_POLICY,
  NAV_TERMS,
} from "../../routes/routes.constant";

function LpFooter() {
  return (
    <Footer className="sp-footer">
      <ul>
        <li>
          <Link to={getNavPath(NAV_ABOUT_US)}>About Us</Link>
        </li>
        <li>
          <a href="https://bitanapay.tawk.help/" target="_blank" rel="noreferrer">
            Help Center
          </a>
        </li>
        <li>
          <Link to={getNavPath(NAV_PRIVACY_POLICY)}>Privacy</Link>
        </li>
        <li>
          <Link to={getNavPath(NAV_TERMS)}>Terms of Use</Link>
        </li>
      </ul>
      <ul>
        <li>
          <a
            href="https://twitter.com/bitanapay?t=qhGsm8yEuLXrS8jn-hn_Hg&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/bitanapay/"
            target="_blank"
            rel="noreferrer"
          >
            <ion-icon name="logo-linkedin"></ion-icon>
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/bitanapayhq?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noreferrer"
          >
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
        </li>
      </ul>
    </Footer>
  );
}

export default LpFooter;
