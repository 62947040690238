const examsIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5195 12.1582C14.6856 12.0775 14.8788 12.1984 14.8788 12.3831V14.4252C14.8788 15.3086 14.3622 16.1002 13.5538 16.4419C12.3705 16.9419 11.1705 17.1919 9.96218 17.1919C8.75384 17.1919 7.53718 16.9419 6.32052 16.4419C5.51218 16.1002 4.97885 15.3002 4.97885 14.4086V12.3092C4.97885 12.1243 5.17266 12.0034 5.33875 12.0846L8.12885 13.4502C8.68659 13.7848 9.35418 13.8919 9.99551 13.8919C10.6372 13.8919 11.2705 13.7419 11.8622 13.4502L14.5195 12.1582Z"
      fill={fill}
    />
    <path
      opacity="0.4"
      d="M16.8624 9.85839C17.6374 9.47506 18.1207 8.70006 18.1207 7.83341C18.1207 6.96674 17.6374 6.18341 16.8541 5.80008L11.4041 3.13341C10.5124 2.70008 9.48741 2.70008 8.58741 3.13341L3.14573 5.80008C2.36239 6.18341 1.87906 6.95841 1.87906 7.82508C1.87906 8.55839 2.22073 9.22506 2.79573 9.64173L3.91239 10.2251L5.05406 10.7834C5.07906 10.8001 5.09573 10.8167 5.12073 10.8251L8.58741 12.5167C9.02466 12.7354 9.50524 12.8417 9.99574 12.8417C10.4791 12.8417 10.9541 12.7334 11.4041 12.5167L16.8624 9.85839Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.90846 10.2359C4.01679 9.97761 4.25846 9.77761 4.55846 9.72761L7.68346 9.23594C8.02512 9.17761 8.25846 8.86094 8.20846 8.51927C8.15012 8.17756 7.82512 7.94423 7.49179 7.99423L4.36679 8.48594C3.66226 8.60194 3.08963 9.04777 2.79651 9.64294L2.7954 9.64219C2.66207 9.92552 2.58707 10.2339 2.58707 10.5589V13.9589C2.58707 14.3089 2.8704 14.5839 3.21207 14.5839C3.55373 14.5839 3.83707 14.3089 3.83707 13.9589V10.5589C3.83707 10.4459 3.86047 10.3331 3.90727 10.2353L3.90846 10.2359Z"
      fill={fill}
    />
  </svg>
);

export default examsIcon;
