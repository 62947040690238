const airtimeIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M12.0153 4.57494C11.6186 4.50166 11.2542 4.75482 11.1787 5.14205C11.1032 5.52929 11.3571 5.90736 11.743 5.98314C12.9049 6.20965 13.8021 7.10903 14.0295 8.2749V8.27573C14.0942 8.61134 14.3897 8.85534 14.7299 8.85534C14.7756 8.85534 14.8212 8.85117 14.8677 8.84284C15.2536 8.7654 15.5076 8.38816 15.432 8.00009C15.0926 6.25879 13.7523 4.91388 12.0153 4.57494Z"
        fill={fill}
      />
      <path
        d="M11.9634 1.67326C11.7775 1.64662 11.5907 1.70158 11.4422 1.81983C11.2895 1.93975 11.194 2.11296 11.1733 2.307C11.1293 2.69923 11.4123 3.05398 11.804 3.09812C14.5054 3.39958 16.6051 5.50397 16.9089 8.21377C16.9495 8.57686 17.2541 8.85084 17.6176 8.85084C17.645 8.85084 17.6716 8.84917 17.6989 8.84584C17.889 8.82502 18.0583 8.73092 18.1778 8.58102C18.2965 8.43112 18.3504 8.24459 18.3289 8.05388C17.9504 4.67287 15.3337 2.04884 11.9634 1.67326Z"
        fill={fill}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.19311 10.8103C12.5173 14.1336 13.2715 10.2889 15.388 12.404C17.4286 14.444 18.6013 14.8527 16.016 17.4373C15.6922 17.6975 13.6347 20.8286 6.40383 13.5997C-0.827909 6.37 2.30131 4.31037 2.56163 3.98662C5.15321 1.39487 5.55488 2.57449 7.5954 4.61444C9.71196 6.73041 5.86887 7.48701 9.19311 10.8103Z"
      fill={fill}
    />
  </svg>
);

export default airtimeIcon;
