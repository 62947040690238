import { Result } from "antd";
import { useRouteError } from "react-router-dom";
import LpButton from "./ui/Button/Button";
import img404 from "./assets/svgs/error-404.svg";
import img406 from "./assets/svgs/error-406.svg";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  const is404Page = error?.status === 404;

  const CustomIcon = () => (
    <img src={is404Page ? img404 : img406} alt="Custom Icon" />
  );

  return (
    <div id="error-page" className="error-page-main">
      <Result
        title={is404Page && "Chill a Bit"}
        subTitle={
          is404Page
            ? "We're sorry, but the page you're looking for seems to have gone on a relaxing vacation. Maybe it's taking a break to chill a bit and will be back soon."
            : error.message || "Sorry, the page you visited does not exist."
        }
        extra={
          <LpButton
            label="Back Home"
            onClick={() => (window.location.href = "/")}
          />
        }
        icon={<CustomIcon />}
      />
    </div>
  );
}
