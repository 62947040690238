import React from "react";
import _noop from "lodash/noop";

function Tag({ label, customClass, disabled, onClick }) {
  return (
    <div
      className={`tag ${customClass}`}
      style={{ opacity: !disabled ? 1 : 0.5 }}
      onClick={!disabled ? onClick : _noop}
    >
      <span>{label}</span>
    </div>
  );
}

export default Tag;
