import electricityIcon from "../../assets/svgs/menu-icons/electricity";
// import cableIcon from "../../assets/svgs/menu-icons/cable";
// import airtimeIcon from "../../assets/svgs/menu-icons/airtime";
// import internetIcon from "../../assets/svgs/menu-icons/internet";
// import bettingIcon from "../../assets/svgs/menu-icons/betting";
// import examsIcon from "../../assets/svgs/menu-icons/exams";
// import transferIcon from "../../assets/svgs/menu-icons/transfer";
import transactionsIcon from "../../assets/svgs/menu-icons/transactions";
import accountIcon from "../../assets/svgs/menu-icons/account";
import {
  NAV_ACCOUNT,
  NAV_ELECTRICITY,
  NAV_TRANSACTIONS,
} from "../../routes/routes.constant";

export const MENU_KEYS = {
  electricity: "electricity",
  cable: "cable",
  airtime: "airtime",
  internet: "internet",
  betting: "betting",
  exams: "exams",
  transfer: "transfer",
  transactions: "transactions",
  account: "account",
};

export const dashboardMenus = (activeKey) => {
  const _buildIcon = (key, menuIcon) => {
    return menuIcon({ fill: key === activeKey ? "#7535fd" : undefined });
  };
  const {
    electricity,
    // cable,
    // airtime,
    // internet,
    // betting,
    // exams,
    // transfer,
    transactions,
    account,
  } = MENU_KEYS;
  return [
    {
      key: electricity,
      label: "Buy Electricity",
      icon: _buildIcon(electricity, electricityIcon),
      children: null,
    },
    // {
    //   key: cable,
    //   label: "Cable TV",
    //   icon: _buildIcon(cable, cableIcon),
    //   children: null,
    // },
    // {
    //   key: airtime,
    //   label: "Airtime",
    //   icon: _buildIcon(airtime, airtimeIcon),
    //   children: null,
    // },
    // {
    //   key: internet,
    //   label: "Internet",
    //   icon: _buildIcon(internet, internetIcon),
    //   children: null,
    // },
    // {
    //   key: betting,
    //   label: "Betting",
    //   icon: _buildIcon(betting, bettingIcon),
    //   children: null,
    // },
    // {
    //   key: exams,
    //   label: "Exams",
    //   icon: _buildIcon(exams, examsIcon),
    //   children: null,
    // },
    // {
    //   key: transfer,
    //   label: "Transfers",
    //   icon: _buildIcon(transfer, transferIcon),
    //   children: null,
    // },
    {
      key: transactions,
      label: "Transactions",
      icon: _buildIcon(transactions, transactionsIcon),
      children: null,
    },
    {
      key: account,
      label: "My Account",
      icon: _buildIcon(account, accountIcon),
      children: null,
    },
  ];
};

export const nonPendingPages = [NAV_ELECTRICITY, NAV_TRANSACTIONS, NAV_ACCOUNT];
