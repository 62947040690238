import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../error-page";
import Homepage from "../pages/Homepage/Homepage";

import LpLayout from "../components/Layout/Layout";
import LpBlankLayout from "../components/BlankLayout/BlankLayout";
import Signin from "../pages/Signin/Signin";
import Signup from "../pages/Signup/Signup";
import SignupVerifyCode from "../pages/Signup/EnterCode";
import SigninVerifyCode from "../pages/Signin/EnterCode";
import TransactionPin from "../pages/Signup/TransactionPin";

import {
  NAV_ABOUT_US,
  NAV_ACCOUNT,
  NAV_AUTHORIZE_TRANSACTION,
  NAV_COMING_SOON,
  NAV_ELECTRICITY,
  NAV_LOGIN,
  NAV_PAYMENTS_BANK_TRANSFER,
  NAV_PRIVACY_POLICY,
  NAV_SIGN_UP,
  //NAV_SIGN_UP_BASE,
  NAV_TERMS,
  NAV_TRANSACTIONS,
  NAV_UPDATE_TRANSACTION_PIN,
  NAV_UPDATE_TRANSACTION_PIN_VERIFY,
} from "./routes.constant";

import { getNavPath } from "./routes.helpers";
import SetLoginPin from "../pages/Signin/SetPin";
import ComingSoon from "../pages/ComingSoon/ComingSoon";
import AboutUs from "../pages/AboutUs/AboutUs";
import TermsOfUse from "../pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Transactions from "../pages/Services/Transactions";
import MyAccount from "../pages/Services/MyAccount/MyAccount";
import { isStaging } from "../helpers/server";
import { Electricity } from "../pages/Services/Electricity";
import ElectricityInputDetails from "../pages/Services/Electricity/InputDetails";
import PaymentDetails from "../components/PaymentDetails/PaymentDetails";
import TransactionCompleted from "../components/Transaction/completed";
import BankTransfer from "../pages/Payment/BankTransfer";
import { ModalProvider } from "../context/ModalContext";
import PaymentConfirmation from "../pages/Payment/PaymentConfirmation";
import SignupIndex from "../pages/Signup";
import SigninIndex from "../pages/Signin";
import AuthorizeTransactionWithPin from "../components/Transaction/pin";
import ChangePin from "../pages/Services/MyAccount/SubPages/UpdateTransactionPin/ChangePin";
import UpdateTransactionPinVerify from "../pages/Services/MyAccount/SubPages/UpdateTransactionPin/EnterCode";

const AppLayout = ({ rootClass = '', Component, hasSideMenu, ...props }) => (
  <ModalProvider>
    <LpLayout hasSideMenu={hasSideMenu} rootClass={rootClass} {...props}>
      <Component />
    </LpLayout>
  </ModalProvider>
);

const BlankLayout = ({ Component }) => (
  <ModalProvider>
    <LpBlankLayout>
      <Component />
    </LpBlankLayout>
  </ModalProvider>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout Component={Homepage} />,
    errorElement: <ErrorPage />,
  },
  {
    path: `${getNavPath(NAV_LOGIN)}/:tab?`,
    element: <BlankLayout Component={SigninIndex} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Signin />,
      },
      {
        path: `verify`,
        element: <SigninVerifyCode />,
      },

      {
        path: `pin/set`,
        element: <SetLoginPin />,
      },
    ],
  },
  {
    path: `${getNavPath(NAV_SIGN_UP)}/:tab?`,
    element: <BlankLayout Component={SignupIndex} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Signup />,
      },
      {
        path: `verify`,
        element: <SignupVerifyCode />,
      },
      {
        path: `pin`,
        element: <TransactionPin />,
      },
    ],
  },
  {
    path: getNavPath(NAV_PAYMENTS_BANK_TRANSFER),
    element: <BlankLayout Component={BankTransfer} />,
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_ELECTRICITY) + "/:tab?",
    element: <AppLayout Component={Electricity} hasSideMenu rootClass="sp-dashboard" isProtected={true} />,
    children: [
      {
        path: "",
        element: <ElectricityInputDetails />,
      },
      {
        path: "payment-details",
        element: <PaymentDetails />,
      },
      {
        path: "done",
        element: <TransactionCompleted />
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_AUTHORIZE_TRANSACTION),
    element: <BlankLayout Component={AuthorizeTransactionWithPin} />,
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_UPDATE_TRANSACTION_PIN),
    element: <BlankLayout Component={ChangePin} />,
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_UPDATE_TRANSACTION_PIN_VERIFY),
    element: <BlankLayout Component={UpdateTransactionPinVerify} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/payment/confirmation",
    element: <PaymentConfirmation />,
    errorElement: <ErrorPage />,
  },
  {
    path: `${getNavPath(NAV_TRANSACTIONS)}`,
    element: <AppLayout Component={Transactions} hasSideMenu rootClass="sp-dashboard" isProtected={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: `${getNavPath(NAV_ACCOUNT)}`,
    element: (
      <AppLayout
        Component={!isStaging() ? ComingSoon : MyAccount}
        hasSideMenu
        rootClass="sp-dashboard" 
        isProtected={true}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: `${getNavPath(NAV_ACCOUNT)}/:id`,
    element: <AppLayout Component={MyAccount} hasSideMenu />,
    errorElement: <ErrorPage />,
  },
  {
    path: `${getNavPath(NAV_COMING_SOON)}`,
    element: <AppLayout Component={ComingSoon} hasSideMenu />,
    errorElement: <ErrorPage />,
  },
  /* {
    path: `${getNavPath(NAV_SIGN_UP_BASE)}`,
    element: <BlankLayout Component={SignupBase} />,
    errorElement: <ErrorPage />,
  }, */
  {
    path: getNavPath(NAV_ABOUT_US),
    element: <AppLayout Component={AboutUs} />,
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_TERMS),
    element: <AppLayout Component={TermsOfUse} />,
    errorElement: <ErrorPage />,
  },
  {
    path: getNavPath(NAV_PRIVACY_POLICY),
    element: <AppLayout Component={PrivacyPolicy} />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
