import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  SEND_OTP,
  VERIFY_OTP,
  OTP_RESET,
} from '../types';

// Action creator for sending OTP with promise action
export const sendOtp = createPromiseAction(SEND_OTP);

// Action creator for verifying OTP with promise action
export const verifyOtp = createPromiseAction(VERIFY_OTP);

// Action creator to reset OTP state
export const resetOtpState = () => ({
  type: OTP_RESET,
});
