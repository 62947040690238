import React from 'react';
import GetStarted from "../../components/Modal/ModalComponents/GetStarted";
import TransactionPin from './../../ui/TransactionPin/TransactionPin';
import { useModal } from '../../context/ModalContext';

const useSpModal = () => {
  const { showModal } = useModal();

  const getStarted = (props = {}) => {
    showModal(GetStarted, props);
  };

  const showTransactionPin = (props = {}) => {
    showModal(TransactionPin, props);
  };

  return {
    getStarted,
    showTransactionPin
  };
};

export default useSpModal;
