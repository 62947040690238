import { createReducer } from '@reduxjs/toolkit';
import {
  login,
  register,
} from '../actions/auth';
import { LOGIN_RESET, REGISTER_RESET } from './../types';

const initialState = {
  login: {
    loading: false,
    error: null,
    data: null
  },
  register: {
    loading: false,
    error: null,
    data: null
  }
};

const authReducer = createReducer(initialState, {
  // Login actions
  [login.trigger]: (state) => {
    state.login.loading = true;
    state.login.error = null;
    state.login.data = null;
  },
  [login.resolved]: (state, action) => {
    state.login.loading = false;
  },
  [login.rejected]: (state, action) => {
    state.login.loading = false;
    state.login.error = action.payload;
  },
  [LOGIN_RESET]: (state) => ({
    ...state,
    login: initialState.login
  }),

  // Register actions
  [register.trigger]: (state) => {
    state.register.loading = true;
    state.register.error = null;
  },
  [register.resolved]: (state, action) => {
    state.register.loading = false;
    state.register.data = null;
    state.register.error = null;
  },
  [register.rejected]: (state, action) => {
    state.register.loading = false;
    state.register.error = action.payload;
  },
  [REGISTER_RESET]: (state) => ({
    ...state,
    register: initialState.register
  }),
});

export default authReducer;
