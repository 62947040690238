import React, { useEffect } from "react";
import {
  AllArticles,
  AsideSection,
  Hero,
  ProfileInfo,
} from "./PrivacyPolicy.helper";
import ScrollToTopOnMount from "../../components/ScrollToTop/ScrollToTop";

const PrivacyPolicy = () => {
  const ScrollToArticle = () => {
    useEffect(() => {
      const asideLinks = document.querySelectorAll("aside a");

      const handleClick = function (event) {
        event.preventDefault();

        const targetId = this.getAttribute("href").slice(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      asideLinks.forEach((link) => {
        link.addEventListener("click", handleClick);
      });

      return () => {
        asideLinks.forEach((link) => {
          link.removeEventListener("click", handleClick);
        });
      };
    }, []);

    return null;
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="privacyPolicy">
        <div className="privacyPolicy__inner">
          <section className="privacyPolicy__inner-article">
            <h1>Privacy Policy</h1>
            <Hero />
            <ProfileInfo />
            <AllArticles />
          </section>
          <aside className="privacyPolicy__inner-aside">
            <ScrollToArticle />
            <AsideSection />
          </aside>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
