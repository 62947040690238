export const NAV_ELECTRICITY = "electricity";
export const NAV_ACCOUNT = "account";
export const NAV_TRANSACTIONS = "transactions";

// auth navs
export const NAV_LOGIN = "login";
export const NAV_SIGN_UP = "signup";
export const NAV_SIGN_UP_VERIFY = "signup/verify";
export const NAV_SIGN_UP_CODE = "auth/code";
export const NAV_SIGN_UP_PIN = "signup/pin";
//export const NAV_SIGN_UP_BASE = "signup/account-type";
export const NAV_SIGN_UP_BASE = "signup";

export const NAV_COMING_SOON = "coming-soon";

export const NAV_ABOUT_US = "about-us";
export const NAV_TERMS = "terms";
export const NAV_PRIVACY_POLICY = "privacy-policy";

export const NAV_PAYMENTS_BANK_TRANSFER = "payments/bank-transfer";

export const NAV_AUTHORIZE_TRANSACTION = "transaction-pin/authorize";
export const NAV_UPDATE_TRANSACTION_PIN = "/account/transaction-pin/update";
export const NAV_UPDATE_TRANSACTION_PIN_VERIFY = "/account/transaction-pin/otp/verify";
