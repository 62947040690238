import apiClient from "../api/apiClient";

export const loginRequest = (payload) => {

  let url = null;

  if (payload?.isGuest) {
    url = '/auth/guest/login';
  }
  else {
    url = '/auth/login';
  }

  return apiClient({
    method: "POST",
    url: url,
    data: payload,
  });
}

export const refreshTokenRequest = () => {
  return apiClient({
    method: "POST",
    url: '/auth/refresh-token',
    //data: payload,
  });
}

export const registerRequest = (payload) =>
  apiClient({
    method: "POST",
    url: "/user/new",
    data: payload,
  });

