import React, { useEffect } from 'react';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

nProgress.configure({ showSpinner: false });

const SpLoadingOverlay = ({ children, isLoading, noSpinner = true, noProgressBar = false, style }) => {

  useEffect(() => {
    if (isLoading && !noProgressBar) {
      nProgress.start();
      nProgress.set(0.1)
    } else {
      nProgress.done();
    }
  }, [isLoading]);

  return (
    <div className="sp-loading__container sp-steps__content" style={style}>
      {children}
      {isLoading && (
        <div className="sp-loading__overlay">
          <div className={`sp-loading__spinner${noSpinner ? " hide" : ""}`}></div>
        </div>
      )}
    </div>
  );
};

export default SpLoadingOverlay;
