import { Outlet, useLocation } from "react-router-dom";
import SpSteps from "../../../sp-components/SpSteps/SpSteps";
import { pathToIndex } from '../../../utils/utils';

//Order of precedence
const tabs = [
  "electricity", "payment-details", "done"
];

const Electricity = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const stepItems = [
    { title: "Start", icon: <></> },
    { title: "Confirmation", icon: <></> },
    { title: "Completed", icon: <></> }
  ];

  return (
    <>
      <SpSteps items={stepItems} current={pathToIndex(pathName, tabs)} />
      <div style={{backgroundColor: '#fff', borderRadius: 16, marginTop: 8}}>
        <Outlet />
      </div>
    </>
  );
};

export default Electricity;
