import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const SpInputButtonGroup = ({ options, disabledOptions, selectedValue, onChange, multiSelect = false }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (!multiSelect) {
      setSelectedValues((value) => [selectedValue]);
      onChange(selectedValue);
    }

  }, [selectedValue]);

  const handleButtonClick = (value) => {
    if (!disabledOptions.includes(value)) {
      if (multiSelect) {
        const newSelectedValues = selectedValues.includes(value)
          ? selectedValues.filter((item) => item !== value)
          : [...selectedValues, value];
        setSelectedValues(newSelectedValues);
        onChange(newSelectedValues);
      } else {
        setSelectedValues([value]);
        onChange(value);
      }
    }
  }

  return (
    <div className="sp-input-button-group">
      {options.map((value) => (
        <button
          type='button'
          key={value}
          className={`sp-input-button-group__button ${selectedValues.map(String).includes(value + "") ? 'selected' : ''} ${disabledOptions.map(String).includes(value + "") ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(value)}
          disabled={disabledOptions.includes(value)}
        >
          {value}
          {/* {selectedValues.includes(value) && <span className="sp-input-button-group__checkmark">✔</span>} */}
        </button>
      ))}
    </div>
  );
};

SpInputButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  disabledOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onChange: PropTypes.func,
  multiSelect: PropTypes.bool,
  selectedValue: PropTypes.any
};

SpInputButtonGroup.defaultProps = {
  disabledOptions: [],
  onChange: () => {},
  multiSelect: false,
  selectedValue: ''
};

export default SpInputButtonGroup;
