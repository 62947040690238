import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  LOCAL_UPDATE_USER,
  RESET_USER_STATE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from '../types';

// Fetch user actions with promise action
export const fetchUser = createPromiseAction(FETCH_USER);

// Update user actions with promise action
export const updateUser = createPromiseAction(UPDATE_USER);

// Local update action
export const localUpdateUser = (data) => ({
  type: LOCAL_UPDATE_USER,
  payload: data,
});

// Logout actions with promise action
export const logout = createPromiseAction(LOGOUT);

// Action creators for fetch user success and failure
export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: data,
});

export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

// Action creators for update user success and failure
export const updateUserSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: data,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});

// Logout success and failure actions
export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});

// Reset user state
export const resetUserState = () => ({
  type: RESET_USER_STATE,
});
