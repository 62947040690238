/* eslint-disable import/no-anonymous-default-export */
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
import rootSaga from "./sagas";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import jwt_decode from "jwt-decode";
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import { getAccessToken } from "../utils/utils";


var decoded = {};
const accessToken = getAccessToken();

if (accessToken) {
  try {
    decoded = jwt_decode(accessToken);
  }
  catch(e) {}
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["modal"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
const sagaMiddleware = createSagaMiddleware();

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: () => [promiseMiddleware, sagaMiddleware, logger],
    preloadedState: {
      user: {
        current: decoded,
      },
    },
    devTools: process.env.NODE_ENV !== "production",
  });
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
