import React from "react";
import WavyBuddies from "../../assets/svgs/wavy-buddies.svg";

function Layout({
  children,
  title,
  subTitle,
}) {
  return (
    <div className="auth" style={{marginTop: 24}}>
      <div className="centered_text">
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      <div className="layout__inner">{children}</div>
      <img className="character" src={WavyBuddies} alt="" />
    </div>
  );
}

export default Layout;
