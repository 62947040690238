
import { Button } from 'antd';
import { formatAmount } from './../../helpers/numbers';
import { PlusOutlined } from '@ant-design/icons';

const SpWalletCard = ({availableBalance = 0}) => {
    return (
        <div className="sp-wallet-card">
            <div style={{marginTop: "40px"}}>
                <h4 className="sp-wallet-card__title">Available Balance</h4>
                <p className="sp-wallet-card__text" style={{marginTop: -8}}>
                    <span className='sp-wallet-card__currency'>NGN</span>{formatAmount(availableBalance)}
                </p>
            </div>
            <div>
                <Button 
                    style={{
                        color: "#19083d",
                        backgroundColor: "#d2c9e8",
                        opacity: 0.8
                    }}
                    type="default" 
                    shape='circle' 
                    size="large"
                    icon={<PlusOutlined />}
                >
                </Button>
            </div>
        </div>
    );
}

export default SpWalletCard;