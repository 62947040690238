import React from "react";
import TransactionPin from "../../../ui/TransactionPin/TransactionPin";

const ModalTransactionPin = ({ onComplete }) => {
  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Enter Transaction PIN</h3>
        <p>Your PIN required to approve this transaction</p>
      </div>

      <TransactionPin onComplete={onComplete} />
    </div>
  );
};

export default ModalTransactionPin;
