import React, { useCallback, useState, useEffect } from "react";
import LpButton from "../../../../ui/Button/Button";
import LpInput from "../../../../ui/Input/Input";
import OtpInput from "react-otp-input";
import SuccessModal from "../SuccessModal";
import { message } from "antd";
import {
  sendVerifyBvnOTP,
  reserveVirtualAccount,
  verifyBvnOTP,
} from "../../../../api/user";

const VerifyBvn = ({ currentDisplay }) => {
  const [bvn, setBvn] = useState("");
  const [display, setDisplay] = useState("bvn");
  const [otp, setOTP] = useState("");
  const [error, setError] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const handleChange = useCallback((e) => setBvn(e.target.value), []);

  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);

  useEffect(() => {
    const savedBvn = localStorage.getItem("savedBvn");
    if (savedBvn) {
      setBvn(savedBvn);
    }
  }, []);

  const handleBvnSubmit = useCallback(async () => {
    if (bvn.length === 0) {
      messageApi.error({
        key,
        content: "BVN cannot be empty",
        duration: 2,
      });
      return;
    }

    if (bvn.length !== 11) {
      messageApi.error({
        key,
        content: "BVN must be 11 characters long",
        duration: 2,
      });
      return;
    }

    try {
      localStorage.setItem("savedBvn", bvn);

      messageApi.loading({
        key,
        content: "Loading...",
      });

      const response = await sendVerifyBvnOTP();

      if (response.status === 200) {
        setDisplay("otp");
      } else {
        messageApi.error({
          key,
          content: "Failed to send OTP",
          duration: 2,
        });
      }
    } catch (otpError) {
      messageApi.error({
        key,
        content: otpError?.message || "Something went wrong",
        duration: 2,
      });
    }
  }, [bvn, display]);

  const handleOtpInput = (e) => {
    if (error) {
      setError(false);
    }
    setOTP(e);
  };

  const handleSubmitOtp = async () => {
    messageApi.loading({
      key,
      content: "Loading...",
    });

    try {
      const otpInfo = {
        otp: otp,
      };

      const response = await verifyBvnOTP(otpInfo);

      if (response.status === 200) {
        try {
          const savedBvn = localStorage.getItem("savedBvn");

          if (savedBvn) {
            const bvResponse = await reserveVirtualAccount({ bvn: savedBvn });

            if (bvResponse.status === 200) {
              localStorage.setItem("AccountInfo", bvResponse);
              currentDisplay("success");
              setDisplay("success");
              localStorage.removeItem("savedBvn");
            } else {
              messageApi.error({
                key,
                content: "Failed to verify BVN",
                duration: 2,
              });
            }
          } else {
            messageApi.error({
              key,
              content: "BVN not found in local storage",
              duration: 2,
            });
          }
        } catch (verifyBvnError) {
          console.error(verifyBvnError);
          messageApi.error({
            key,
            content: "Failed to verify BVN",
            duration: 2,
          });
        }
      }
    } catch (error) {
      message.error(error?.message || "Something went wrong");
      setError(true);
    }
  };

  return (
    <div className="modal_components">
      {contextHolder}
      {display === "bvn" && (
        <>
          <div className="centered_text">
            <h3>BVN Verification</h3>
            <p>Please enter your 11-digit Bank Verification Number below</p>
          </div>

          <div>
            <label>Bank Verification Number</label>
            <LpInput
              value={bvn}
              inputMode="number"
              placeholder="Eg. 22978579898"
              onChange={handleChange}
              autoFocus
            />
          </div>

          <div className="modal_components__button">
            <LpButton
              label="Submit"
              customClass="full-width"
              onClick={handleBvnSubmit}
            />
          </div>
        </>
      )}
      {display === "otp" && (
        <>
          <div className="centered_text">
            <h3>Enter Code</h3>
            <p>Please enter the code we sent to your mobile phone:</p>
            <p className="modal_components__primary-text">+23481******45</p>
          </div>
          <div className="justify-content-center modal_components__otp-container">
            <OtpInput
              value={otp}
              onChange={handleOtpInput}
              numInputs={6}
              className="otp-input"
              separator={<div style={{ marginRight: 20 }} />}
              shouldAutoFocus
              isInputNum
              handleKeyUp={handleKeyUp}
            />
            {error && (
              <p className="modal_components__otp-error">
                Invalid OTP. Please try again.
              </p>
            )}

            <div className="otp-btns">
              <button className="otp-btns_transparent">Resend Code</button>
              <LpButton label={"Verify BVN"} onClick={handleSubmitOtp} />
            </div>
          </div>
        </>
      )}
      {display === "success" && (
        <SuccessModal
          title={"BVN Approved"}
          subtitle={"Your submission has been received and approved"}
        />
      )}
    </div>
  );
};

export default VerifyBvn;
