import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  FETCH_SERVICES_BY_CATEGORY,
  FETCH_SERVICE_CATEGORIES,
} from '../types';

// Action creators for fetching service categories
export const fetchServiceCategories = createPromiseAction(FETCH_SERVICE_CATEGORIES);

// Action creators for fetching services by category
export const fetchServicesByCategory = createPromiseAction(FETCH_SERVICES_BY_CATEGORY);

