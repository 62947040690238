import { call, all, takeEvery } from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils';
import { message } from 'antd';
import { updateTransactionPinRequest } from '../../api/account';
import { updateTransactionPin } from '../actions/account';

// Saga to handle initiating a transaction
export function* handleUpdateTransactionPin(action) {
  try {
    const { data } = yield call(updateTransactionPinRequest, action.payload);
    yield call(resolvePromiseAction, action, data?.data ?? data); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    message.error(errorMessage);
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for transaction actions
export default function* accountSaga() {
  yield all([
    takeEvery(updateTransactionPin.trigger, handleUpdateTransactionPin), // Watch for initiate transaction action
  ]);
}
