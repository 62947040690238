import { all, fork } from "redux-saga/effects";
import authSaga from "./auth.saga";
import modalSaga from "./modal.saga";
import userSaga from "./user.saga";
import serviceSaga from "./service.saga";
import accountValidationSaga from "./accountValidation.saga";
import otpVerificationSaga from "./otpVerification.saga";
import transactionSaga from "./transaction.saga";
import accountSaga from "./account.saga";

export default function* rootSaga() {
  const rootSaga = [
    fork(authSaga), 
    fork(modalSaga), 
    fork(userSaga), 
    fork(serviceSaga),
    fork(accountValidationSaga),
    fork(otpVerificationSaga),
    fork(transactionSaga),
    fork(accountSaga)
  ];
  yield all(rootSaga);
}
