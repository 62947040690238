import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";
import { Col, Divider, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import _map from "lodash/map";
import ServiceTile from "../../ui/ServiceTile/ServiceTile";
import { services } from "./Homepage.helper";
import { useNavigate } from "react-router";
import { fetchServiceCategories } from "../../redux/actions/service";
import SpHighlight from "../../sp-components/SpHighlight/SpHighlight";
import heroImg from "../../assets/images/hero.png";
import withUserData from "../../HOC/withUserData";
import useSpModal from "../../sp-components/SpModal/SpModal";
import SpWalletCard from "../../sp-components/SpWalletCard/SpWalletCard";
import SpLoadingOverlay from './../../sp-components/SpLoadingOverlay/SpLoadingOverlay';


function Homepage({isGuest = true, isLoggedIn, userData}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {getStarted} = useSpModal();

  const { data: categories, loading: isLoading, error: categoriesError } = useSelector(state => state.service.categories);

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(null);
  const [serviceList, setServiceList] = useState(services);


  const wordExists = useCallback((key, service) => {
    const regex = new RegExp(key, "i");
    return regex.test(service);
  }, []);

  useEffect(() => {
    dispatch(fetchServiceCategories());
  }, []);

  useEffect(() => {
    if (value) {
      setServiceList(
        serviceList.filter(({ label }) => wordExists(value, label))
      );
      return;
    }
    if (value === "") {
      setServiceList(services);
    }
  }, [value, serviceList, wordExists]);

  const handleServiceClick = useCallback(
    (item, i) => {
      const { label, url, status } = item;

      if (status.toLowerCase() !== "active") {
        return;
      }

      if (isLoggedIn) {
        navigate(url);
        return;
      }
      
      getStarted({
        redirectTo: url,
      });

    },
    [dispatch, navigate, isGuest, isLoggedIn]
  );

  return (
    <SpLoadingOverlay isLoading={isLoading}>
      <div className="homepage" style={{marginTop: -24}}>
       <Content className="homepage__inner-container">
            <h5 style={{
              fontSize: 16,
              marginBottom: 14,
              fontWeight: 500,
              fontFamily: 'General Sans Medium'
            }}>Hello, {userData?.firstName?.length > 0? userData?.firstName : 'Guest'}! 👋</h5>
            <SpWalletCard />
            <div style={{padding: "0px 16px"}}>
              <Divider  />
            </div>
            <h4 className="homepage__title">
              Quick <SpHighlight>Access</SpHighlight>
            </h4>
            <div className="homepage__biller-grid">
            {_map(
                categories,
                ({ name: label, image_url: icon, bg_color: bgColor, slug: url, status }, i) => (
                  <ServiceTile
                    key={i}
                    label={label}
                    icon={icon}
                    url={url}
                    bgColor={bgColor}
                    onClick={() => handleServiceClick({ label, url, status }, i)}
                    opacity={status.toLowerCase() === 'active' ? null : 0.3}
                  />
                )
              )}
            </div>
          </Content>
    </div>
    </SpLoadingOverlay>
  );
}

export default withUserData(Homepage);
