import { useEffect, useCallback, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 300000) => { // 300000ms = 5mins
    const timerRef = useRef(null);

    const resetTimer = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            onIdle();
        }, timeout);
    }, [onIdle, timeout]);

    const handleActivity = () => {
        resetTimer();
    };

    useEffect(() => {
        // Start the timer on mount
        resetTimer();

        // Event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('scroll', handleActivity);
        window.addEventListener('click', handleActivity);

        return () => {
            clearTimeout(timerRef.current);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('scroll', handleActivity);
            window.removeEventListener('click', handleActivity);
        };
    }, [resetTimer]);

};

export default useIdleTimer;
