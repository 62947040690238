import AccessCheck from "./AccessCheck";
import GetStarted from "./GetStarted";
import TransactionPin from "./TransactionPin";
import AddressVerification from "./UserAccountModals/AddressVerification";
import DeleteBeneficiary from "./UserAccountModals/DeleteBeneficiary";
import EditBeneficiary from "./UserAccountModals/EditBeneficiary";
import RecurringPayments from "./UserAccountModals/RecurringPayments";
import VerifyBvn from "./UserAccountModals/VerifyBvn";
import VerifyIDCard from "./UserAccountModals/VerifyIDCard";

export const GET_STARTED = "auth/getStarted";
export const ACCESS_CHECK = "auth/accessCheck";
export const TRANSACTION_PIN = "auth/transaction";
export const EDIT_BENEFICIARY = "user/beneficiary";
export const DELETE_BENEFICIARY = "user/delete";
export const VERIFY_BVN = "user/verify/bvn";
export const VERIFY_ID_CARD = "user/verify/card";
export const ADDRESS_VERIFICATION = "user/address/verify";
export const RECURRING_PAYMENTS = "user/recurring/payments";

const modalComponents = {
  [GET_STARTED]: GetStarted,
  [ACCESS_CHECK]: AccessCheck,
  [TRANSACTION_PIN]: TransactionPin,
  [EDIT_BENEFICIARY]: EditBeneficiary,
  [DELETE_BENEFICIARY]: DeleteBeneficiary,
  [VERIFY_BVN]: VerifyBvn,
  [VERIFY_ID_CARD]: VerifyIDCard,
  [ADDRESS_VERIFICATION]: AddressVerification,
  [RECURRING_PAYMENTS]: RecurringPayments,
};

export default modalComponents;
