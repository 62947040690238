import apiClient from "../api/apiClient";

export const initiateTransactionRequest = (data) => {
  const {pin, ...restData} = data || {};

  return apiClient({
    method: "POST",
    url: "/billers/transactions/initiate",
    data: restData,
    headers: {
      pin
    }
  });
}

export const initiateGuestTransaction = (data) =>
  apiClient({
    method: "POST",
    url: "/guest/utility/electricity/initiateTransaction",
    data,
  });

  